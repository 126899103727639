import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrders } from '../../../services/admin.service';
import DataTable from 'react-data-table-component';
import { Button } from '@mui/material';



const CustomersTable = ({
  data = {},
  handlePageChange,
  currentPage
}) => {
  const {
    data: contents = [],
    meta = {}
  } = data;

  const columns = [
    {
      name: "id",
      selector: (row, index) => row.orderId
    },
    {
      name: "Shop",
      selector: (row, index) =>
        <a href={`https://${row.shopId}`} target="_blank" rel="noreferrer">
          {row.shopId}
        </a>
    },
    {
      name: "Email",
      selector: (row, index) => row.email
    },
    {
      name: "Payment",
      selector: (row, index) => (row.paid || 0).toFixed(2)
    },
    {
      name: "Currency",
      selector: (row, index) => row.currency
    },

    {
      name: "Discount",
      selector: (row, index) => (row.discount || 0).toFixed(2)
    },
    {
      name: "Data",
      selector: (row, index) => new Date(row.createdAt).toUTCString()
    },

  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={contents}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={meta?.pagination?.total}
        onChangePage={handlePageChange}
        paginationDefaultPage={currentPage}
      />
    </div>);
}


export default function ViewTransaction() {
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false)

  const { shopId } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const setCustomers = async () => {
      try {
        setLoading(true)
        // const { data } = await getOrders("sad", { page: pageNo });
        const { data } = await getOrders("sad", { page: 1, limit: 10, shopId })
        console.log(data, 'the data')
        setTableData(data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    setCustomers();
  }, [pageNo]);

  const handlePageChange = (page) => {
    setPageNo(page);
  }

  console.log(shopId, 'the shopId')
  return (
    <div className="container mx-2">
      <div class="text-center">
        <h5>Transactions list of {shopId} shop</h5>
      </div>
      <Button variant="contained" onClick={(e) => navigate('/transactions')}>Back</Button>

      {
        loading ? (<h1>Loading...</h1>) : (
          <CustomersTable
            data={tableData}
            handlePageChange={handlePageChange}
            currentPage={pageNo}
          />
        )
      }

    </div>
  )
}
