export default function MultipleInput({
  selecteds = [],
  handleDelete = () => { },
  ...rest
}) {

  return (
    <div className="my-2">
      <div className="selecteds mb-1">
        {
          selecteds.map((value, ind) => (
            <div className="selected" key={`selecteds${ind}`}>
              <p>{value}</p>
              <button onClick={() => { handleDelete(ind, value) }}>x</button>
            </div>
          ))
        }
      </div>
      <textarea className="form-control" {...rest} />
    </div>
  )
}
