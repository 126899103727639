import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const { pathname: currentPath } = useLocation();
  const navigate = useNavigate();

  const handlePathChange = (e) => {
    const {
      name = ""
    } = e.target;

    navigate("/" + name);
  }

  return (
    <div className="sidebar">
      <button
        className={currentPath === "/" ? "active" : ""}
        onClick={handlePathChange}
      >
        Dashboard
      </button>
      <button
        name="customers"
        className={currentPath === "/customers" ? "active" : ""}
        onClick={handlePathChange}
      >
        Customers
      </button>
      <button
        name="transactions"
        className={currentPath === "/transactions" ? "active" : ""}
        onClick={handlePathChange}
      >
        Transactions
      </button>
      <button
        name="subscriptions"
        className={currentPath === "/subscriptions" ? "active" : ""}
        onClick={handlePathChange}
      >
        Subscriptions
      </button>

      <hr style={{ width: "100%" }} />

      <button
        name="words"
        className={currentPath === "/words" ? "active" : ""}
        onClick={handlePathChange}
      >
        Words
      </button>
      <button
        name="contents"
        className={currentPath === "/contents" ? "active" : ""}
        onClick={handlePathChange}
      >
        Contents
      </button>
      {/* <button
        name="supports"
        className={currentPath === "/supports" ? "active" : ""}
        onClick={handlePathChange}
      >
        Support
      </button> */}
    </div>
  )
}
