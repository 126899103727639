import { useEffect, useState } from "react";
import Card from "./components/Card";
import { getDashboardData } from "../../../services/admin.service";
import { Link } from "react-router-dom";


export default function Analysis() {
  const [analysisData, setAnalysisData] = useState({});

  useEffect(() => {
    const setDashboardData = async () => {
      try {
        const { data } = await getDashboardData();
        setAnalysisData(data);
      } catch (error) {
        console.log(error);
      }
    }

    setDashboardData();
  }, [])



  return (
    <div className="container">
      <h5>Analysis</h5>
      <div className="d-flex flex-wrap">
        <div style={{ flex: 1 }}>
          <Card
            title={"Total Customers"}
            sep1={{
              key: "Active",
              value: analysisData?.totalShopAnalysis?.active
            }}
            sep2={{
              key: "Inactive",
              value: analysisData?.totalShopAnalysis?.inActive
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Card
            title={"Total Active Users"}
            sep1={{
              key: "FREE",
              value: analysisData?.totalSubscribers?.inActive
            }}
            sep2={{
              key: "Paid",
              value: analysisData?.totalSubscribers?.active
            }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <Link to='/subscribeUser'>
            <Card
              title={"Total Subscriber"}
              sep1={{
                key: "Active",
                value: analysisData?.totalSubscribers?.active
              }}
              sep2={{
                key: "Inactive",
                value: analysisData?.totalSubscribers?.inActive
              }}
            />
          </Link>
        </div>
      </div>
    </ div>
  )
}
