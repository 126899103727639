import axios from "axios";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URL || "";

export const api = () => {
  const token = sessionStorage.getItem("token");

  if (typeof token === "string" && token.split(".").length === 3) {
    return axios.create({
      baseURL: `${BACKEND_URI}/api/`,
      headers: {
        Authorization: token,
      },
    });
  }
  else
    return axios.create({
      baseURL: `${BACKEND_URI}/api/`,
    });
};

export const handleResponse = (res) => {
  const { success, data } = res;

  if (success === false) {
    const errorMsg = data.message ? data.message : "Something Went Wrong";
    throw new Error(errorMsg);
  }

  return data;
};

export const handleError = (error) => {
  try {
    const { response: { status, statusText } } = error;

    if (status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData")
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }

    throw new Error(statusText);
  } catch (error) {
    throw new Error("Something went Wrong");
  }
};
