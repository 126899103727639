import {
  MDBContainer,
  MDBInput,
  MDBBtn,
} from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";

import { loginUser } from '../../services/auth.service';
import { useState } from 'react';

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });

  const handleLogin = async (e) => {
    try {
      const { data: { token } } = await loginUser("crazy-covertor.myshopify.com", formData);

      sessionStorage.setItem("token", token);
      navigate("/");
    } catch (error) {
      alert("Unable to login");
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  }

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50 login-form" style={{ height: "auto" }}>
      <img src="https://crazy-converter.s3.us-east-2.amazonaws.com/logo.png" alt="logo" className="logo" />
      <MDBInput wrapperClass='mb-4' label='Email address' name='username' value={formData.username} onChange={handleInputChange} type='email' />
      <MDBInput wrapperClass='mb-4' label='Password' name='password' value={formData.password} onChange={handleInputChange} type='password' />

      <MDBBtn className="mb-4" onClick={handleLogin}>Log in</MDBBtn>
    </MDBContainer>
  );
}

