import { api, handleError, handleResponse } from "../config/axios.config";

export const getAllSuggestions = (token, params) =>
  api(token)
    .get(`/suggestion`, { params })
    .then(handleResponse)
    .catch(handleError);

export const deleteSuggestion = (token, params) =>
  api(token)
    .delete(`/suggestion`, { params })
    .then(handleResponse)
    .catch(handleError);

export const addNewSuggestion = (token, data) =>
  api(token)
    .post(`/suggestion`, data)
    .then(handleResponse)
    .catch(handleError);

export const getAllActions = (token, data) =>
  api(token)
    .get(`/actions`, data)
    .then(handleResponse)
    .catch(handleError);
