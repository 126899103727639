import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense } from "react";

import Splash from "../screens/splash/Index";
import ProtectedRouter from "./ProtectedRouter";

import Login from "../screens/login/Index";
import NotFound from "../screens/notFound/Index";

import Dashboard from "../screens/dashboard/Index";
import {
  Content,
  Analysis,
  Cutsomer,
  Subscription,
  Support,
  Transaction,
  Word
} from "../screens/dashboard";
import SubscribeUser from "../screens/dashboard/customers/subscribeUser";
import ViewTransaction from "../screens/dashboard/transactions/viewTransaction";

// const Landing = lazy(() => import("../screens/landing/Landing"));


export default function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Splash />}>
        <Routes>
          <Route element={<ProtectedRouter />} >
            <Route path="/" element={<Dashboard />}>
              <Route path="/" element={<Analysis />} />
              <Route path="/contents" element={<Content />} />
              <Route path="/words" element={<Word />} />
              <Route path="/customers" element={<Cutsomer />} />
              <Route path="/subscriptions" element={<Subscription />} />
              <Route path="/supports" element={<Support />} />
              <Route path="/transactions" element={<Transaction />} />
              <Route path="/subscribeUser" element={<SubscribeUser />} />
              <Route path="/viewTransaction/:shopId" element={<ViewTransaction />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}