
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getOrders, getOrdersList } from "../../../services/admin.service";
import { useNavigate } from "react-router-dom";

const CustomersTable = ({
  data = {},
  handlePageChange,
  handleViewClick,
  currentPage
}) => {
  const {
    data: contents = [],
    meta = {}
  } = data;

  const columns = [
    {
      name: "id",
      selector: (row, index) => row.orderId
    },
    {
      name: "Shop",
      selector: (row, index) =>
        <a href={`https://${row.shopId}`} target="_blank" rel="noreferrer">
          {row.shopId}
        </a>
    },
    // {
    //   name: "Email",
    //   selector: (row, index) => row.email
    // },
    {
      name: "Action",
      button: true,  // Indicates that this column contains a button
      cell: (row) => (
        <button onClick={() => handleViewClick(row)} className="btn btn-primary">
          View
        </button>
      )
    },
    // {
    //   name: "Currency",
    //   selector: (row, index) => row.currency
    // },
    // {
    //   name: "Discount",
    //   selector: (row, index) => (row.discount || 0).toFixed(2)
    // },
    // {
    //   name: "Data",
    //   selector: (row, index) => new Date(row.createdAt).toUTCString()
    // },

  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={contents}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={meta?.pagination?.total}
        onChangePage={handlePageChange}
        paginationDefaultPage={currentPage}
      />
    </div>);
}

export default function Transactions() {
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    setPageNo(page);
  }

  //-----------------------------------------------> use Effects
  useEffect(() => {
    const setCustomers = async () => {
      try {
        setLoading(true)
        // const { data } = await getOrders("sad", { page: pageNo });
        const { data } = await getOrdersList("sad", { page: 1, limit: 10 })
        setTableData(data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    setCustomers();
  }, [pageNo]);

  const handleViewClick = (e) => {
    console.log(e, 'the e')
    navigate(`/viewTransaction/${e.shopId}`)

  }

  return (
    <div className="container mx-2">
      <h5>Transactions</h5>
      {
        loading ? (<h1>Loading...</h1>) : (
          <CustomersTable
            data={tableData}
            handlePageChange={handlePageChange}
            currentPage={pageNo}
            handleViewClick={handleViewClick}
          />
        )
      }

    </div>
  )
}
