import { api, handleError, handleResponse } from "../config/axios.config";

export const loginUser = (token, data) =>
  api(token)
    .post(`/auth/login`, data)
    .then(handleResponse)
    .catch(handleError);

export const getDashboardData = (token, data) =>
  api(token)
    .get(`/auth/dashboard`, data)
    .then(handleResponse)
    .catch(handleError);

export const getSubscribeUser = (page, limit) =>
  api()
    .get(`/auth/subscribeUser?page=${page}&limit=${limit}`)
    .then(handleResponse)
    .catch(handleError);

export const getCustomers = (token, data) =>
  api(token)
    .get(`/auth/customers?page=${data.page}`)
    .then(handleResponse)
    .catch(handleError);

export const getSubscriptions = (token, data) =>
  api(token)
    .get(`/auth/subscription/payment`, data)
    .then(handleResponse)
    .catch(handleError);

export const getAllSituations = (token, data) =>
  api(token)
    .get(`/situations`, data)
    .then(handleResponse)
    .catch(handleError);

export const getOrders = (token, data) =>
  api(token)
    .get(`/auth/orders?page=${data.page}&limit=${data.limit}&shopId=${data.shopId}`)
    .then(handleResponse)
    .catch(handleError);

export const getOrdersList = (token, data) =>
  api(token)
    .get(`/auth/ordersList?page=${data.page}&limit=${data.limit}`)
    .then(handleResponse)
    .catch(handleError)

export const getPlans = (token, data) =>
  api(token)
    .get(`/auth/plans`, data)
    .then(handleResponse)
    .catch(handleError);

export const updatePlans = (token, data) =>
  api(token)
    .post(`/auth/plans`, data)
    .then(handleResponse)
    .catch(handleError);

