import { api, handleError, handleResponse } from "../config/axios.config";

export const getContents = (token, { page, situation }) =>
  api(token)
    .get(`/contents?page=${page}&situation=${situation}`)
    .then(handleResponse)
    .catch(handleError);

export const deleteContent = (token, id) =>
  api(token)
    .delete(`/content?id=${id}`)
    .then(handleResponse)
    .catch(handleError);

export const addorSaveNewContent = (token, data) =>
  api(token)
    .post(`/content`, data)
    .then(handleResponse)
    .catch(handleError);
