import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getAllSituations } from "../../../services/admin.service";
import { addorSaveWord, getAllWords, deleteWord } from "../../../services/word.service";

const WordTable = ({
  data = {},
  situations = [],
  handlePageChange,
  handleWordChange,
  handleDeleteAction,
  handleSituationChange,
  handleSaveOrEditContent,
}) => {
  const {
    data: contents = [],
    meta = {}
  } = data;

  const columns = [
    {
      name: "id",
      selector: (row, index) => row.id
    },
    {
      name: "Situation",
      selector: (row) => (
        <select
          className="form-select"
          onChange={(e) => {
            const { value } = e.target;
            handleSituationChange(row.id, value);
          }}>
          {
            situations.map(({ situation, type, id }) => (
              <option className="m-3" value={id} selected={row.situationId === id} key={`${row.id}-${id}`}>{situation} - {type}</option>
            ))
          }
        </select>
      )
    },
    {
      name: "Words",
      selector: (row) => (
        <input
          type="text"
          className="form-control"
          onChange={(e) => {
            const {
              value
            } = e.target;

            handleWordChange(row.id, value);
          }}
          defaultValue={row?.word} />
      )
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex btn-group">
          <div className="edit-btn">
            <button className="btn btn-success" onClick={() => handleSaveOrEditContent(row)}>
              save
            </button>
          </div>

          {row.id !== "new-content" && <div className="table-delete-btn">
            <button className="btn btn-danger" onClick={() => handleDeleteAction(row)}>
              delete
            </button>
          </div>}
        </div>
      )
    }
  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={contents}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={meta?.pagination?.total}
        onChangePage={handlePageChange}

      />
    </div>);
}

export default function Words() {
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState({});
  const [situations, setSituations] = useState([]);
  const [situtaion, setSitutaion] = useState("All");

  const handleSituationChange = (id, situationId) => {
    const tableValues = tableData?.data || [];

    const contents = tableValues.find(({ id: contentId }) => contentId === id) || {};
    const currentSituaion = situations.find(({ id }) => situationId === id);

    contents.situation = currentSituaion.situation;
    contents.situationId = situationId;
    contents.reference = currentSituaion.type;

    setTableData({
      ...tableData,
    });
  }

  const handleWordChange = (id, word) => {
    const tableValues = tableData?.data || [];
    const contents = tableValues.find(({ id: contentId }) => contentId === id) || {};
    contents.word = word;
    setTableData({
      ...tableData,
    });
  }

  const handleAddNewContent = () => {
    const tableValues = tableData?.data || [];

    if (tableValues?.some(({ id }) => id === "new-content") === false) {
      tableValues.unshift({ id: "new-content", word: "" });
    }
    setTableData({
      ...tableData,
    });
  }

  const handlePageChange = (page) => {
    setPageNo(page);
  }


  const handleSaveOrEditContent = async (data) => {
    try {
      await addorSaveWord("", data);

      const { data: contentData } = await getAllWords("sad", { situation: situtaion, page: pageNo });
      setTableData(contentData);

      alert("Updated Succesfully");
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  }

  const handleDeleteAction = async (data) => {
    try {
      await deleteWord("", data.id);

      const { data: contentData } = await getAllWords("sad", { situation: situtaion, page: pageNo });
      setTableData(contentData);

      alert("Deleted Succesfully");
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  }


  //-----------------------------------------------> use Effects
  useEffect(() => {
    const setTableDatas = async () => {
      try {
        const { data } = await getAllWords("sad", { situation: situtaion, page: pageNo });
        setTableData(data);
      } catch (error) {
        console.log(error);
      }
    }

    setTableDatas();
  }, [pageNo, situtaion]);

  useEffect(() => {
    const getAndSetActions = async () => {
      try {
        setLoading(true)
        const { data: allSituations } = await getAllSituations();
        setSituations(allSituations);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    getAndSetActions();
  }, []);

  return (
    <div className="container">
      <div className="d-flex gap-3 m-3">
        <select
          className="form-select"
          onChange={(e) => {
            const { value } = e.target;

            setSitutaion(value);
          }}>
          <option value={"All"} defaultChecked>All</option>
          {
            situations.map(({ situation, type, id }) => (
              <option value={id} key={`filter-${id}`}>{situation} - {type}</option>
            ))
          }
        </select>
        <button className="btn btn-primary" onClick={handleAddNewContent}>Add</button>
      </div>
      {
        loading ? (<h1>Loading...</h1>) : (
          <WordTable
            data={tableData}
            situations={situations}
            handlePageChange={handlePageChange}
            handleWordChange={handleWordChange}
            handleDeleteAction={handleDeleteAction}
            handleSituationChange={handleSituationChange}
            handleSaveOrEditContent={handleSaveOrEditContent}
          />
        )
      }

    </div>
  );
}