import { api, handleError, handleResponse } from "../config/axios.config";


export const getAllWords = (token, { page, situation }) =>
  api(token)
    .get(`/words?page=${page}&situation=${situation}`)
    .then(handleResponse)
    .catch(handleError);

export const getWords = (token, text) =>
  api(token)
    .get(`/word?text=${text}`)
    .then(handleResponse)
    .catch(handleError);

export const deleteWord = (token, id) =>
  api(token)
    .delete(`/word?id=${id}`)
    .then(handleResponse)
    .catch(handleError);

export const addorSaveWord = (token, data) =>
  api(token)
    .post(`/word`, data)
    .then(handleResponse)
    .catch(handleError);