
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getCustomers } from "../../../services/admin.service";

const CustomersTable = ({
  data = {},
  handlePageChange,
  currentPage
}) => {
  const {
    data: contents = [],
    meta = {}
  } = data;

  const columns = [
    {
      name: "id",
      selector: (row, index) => row.shopSubscription?.[0]?.subscriptionId ?? row.id
    },
    {
      name: "Shop",
      selector: (row, index) =>
        <a href={`https://${row.shopId}`} target="_blank" rel="noreferrer">
          {row.shopId}
        </a>
    },
    {
      name: "Plan",
      selector: (row, index) => row.shopSubscription?.[0]?.name ?? "NA"
    },
    {
      name: "Plan Status",
      selector: (row, index) => (
        <p>
          {row.shopSubscription?.[0]?.status ?? "NA"}
        </p>
      )
    },
    {
      name: "Status",
      selector: (row, index) => (
        <p className="text-uppercase">
          {row.status}
        </p>
      )
    },
    {
      name: "Installed",
      selector: (row, index) => (
        <p>
          {row.isActive.toString() === 'true' ? "YES" : "NO"}
        </p>
      )
    },
    {
      name: "Date",
      selector: (row, index) => new Date(row.shopSubscription?.[0]?.createdAt ?? row.createdAt).toUTCString()
    },
  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={contents}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={meta?.pagination?.total}
        onChangePage={handlePageChange}
        paginationDefaultPage={currentPage}

      />
    </div>);
}

export default function Customers() {
  const [loading, setLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});

  const handlePageChange = (page) => {
    setPageNo(page);
  }
  //-----------------------------------------------> use Effects
  useEffect(() => {
    const setCustomers = async () => {
      try {
        setLoading(true)
        const { data } = await getCustomers("sad", { page: pageNo });
        setTableData(data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    setCustomers();
  }, [pageNo]);


  return (
    <div className="container mx-2">
      <h5>Customers</h5>
      {
        loading ? (
          <h1>Loading..</h1>
        ) : (
          <CustomersTable
            data={tableData}
            handlePageChange={handlePageChange}
            currentPage={pageNo}
          />
        )
      }

    </div>
  )
}
