export default function Card({ title, sep1 = { key: "", value: 0 }, sep2 = { key: "", value: 0 } }) {
  return (
    <div className="card m-2 p-3 justify-content-between" style={{ height: 193, minWidth: 350 }}>
      <div className="d-flex justify-content-between">
        <div>
          <img src="/images/customers.svg" alt="customers" />
        </div>
        <div>
          <h5>{title}</h5>
          <h2 className="fw-bolder">{sep1.value + sep2.value}</h2>
        </div>
      </div>

      <hr />
      <div className="d-flex justify-content-between gap-3">
        <button className="btn btn-primary" style={{ flex: 1 }}>{sep1.key}: {sep1.value}</button>
        <button className="btn btn-primary" style={{ flex: 1 }}>{sep2.key}: {sep2.value}</button>
      </div>
    </div>
  )
}
