import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

export default function Index() {
  return (
    <div>
      <Header />

      <div className="d-flex m-2 py-2">
        <Sidebar />
        <div className="main">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
