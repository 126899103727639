import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { addorSaveNewContent, deleteContent, getContents } from "../../../services/content.service";
import MultipleInput from "../../../components/MultipleInput";
import { getAllActions } from "../../../services/suggestion.service";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getWords } from "../../../services/word.service";

const ContentTable = ({
  currentPage,
  data = {},
  actions = [],
  handlePageChange,
  handleWordChange,
  handleInputFocus,
  handleAddContent,
  handleDeleteAction,
  handleDeleteContent,
  handleSituationChange,
  handleSaveOrEditContent,
}) => {
  const {
    data: contents = [],
    meta = {}
  } = data;
  const [wordOptions, setWordOptions] = useState([]);

  const handleWordInputChange = async (e, value) => {
    try {
      if (value.length) {
        const { data: words } = await getWords("", value);
        setWordOptions(words);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      name: "id",
      selector: (row, index) => row.id
    },
    {
      name: "Situation",
      selector: (row) => (
        <select
          className="form-select"
          onChange={(e) => {
            const { value } = e.target;
            handleSituationChange(row.id, value);
          }}>
          {
            actions.map(({ action, id }) => (
              <option className="m-3" value={action} selected={row.situation === action} key={`${row.id}-${id}`}>{action}</option>
            ))
          }
        </select>
      )
    },
    {
      name: "Contents",
      selector: (row) => (
        <MultipleInput
          onKeyDown={(e) => {
            const {
              value
            } = e.target;
            if (e.key === 'Enter' && value) {
              handleAddContent(row.id, value);
              e.target.value = "";
            }
          }}
          onFocus={() => handleInputFocus(`content-input-${row.id}`)}
          id={`content-input-${row.id}`}
          handleDelete={(ind) => {
            handleDeleteContent(row.id, ind);
          }}
          selecteds={row.contents}
        />
      )
    },
    {
      name: "Word",
      selector: (row) => (
        <div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={wordOptions}
            defaultValue={row.word || ""}
            onChange={(e, value) => handleWordChange(row.id, value)}
            onInputChange={handleWordInputChange}
            sx={{ width: "100%", minWidth: 180 }}
            renderInput={(params) => <TextField {...params} label="Word" />}
          />
        </div>
      )
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex btn-group">
          <div className="edit-btn">
            <button className="btn btn-success" onClick={() => handleSaveOrEditContent(row)}>
              save
            </button>
          </div>

          {row.id !== "new-content" && <div className="table-delete-btn">
            <button className="btn btn-danger" onClick={() => handleDeleteAction(row)}>
              delete
            </button>
          </div>}
        </div>
      )
    }
  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={contents}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={meta?.pagination?.total}
        onChangePage={handlePageChange}
        paginationDefaultPage={currentPage}

      />
    </div>);
}

function Content() {
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState({});
  const [actions, setActions] = useState([]);
  const [situation, setSituation] = useState("All");
  const [seletedInputId, setSeletedInputId] = useState("");

  const handleAddContent = (id, content) => {
    const tableValues = tableData?.data || [];

    const contents = tableValues.find(({ id: contentId }) => contentId === id)?.contents || [];
    contents.push(content);

    setTableData({
      ...tableData,
    });
  }

  const handleInputFocus = (id) => {
    setSeletedInputId(id);
  }

  const handleDeleteContent = (id, index) => {
    const tableValues = tableData?.data || [];

    const contents = tableValues.find(({ id: contentId }) => contentId === id)?.contents || [];
    contents.splice(index, 1);

    setTableData({
      ...tableData,
    });
  }

  const handleSituationChange = (id, situation) => {
    const tableValues = tableData?.data || [];

    const contents = tableValues.find(({ id: contentId }) => contentId === id) || {};
    contents.situation = situation;

    setTableData({
      ...tableData,
    });
  }

  const handleWordChange = (id, value) => {
    const tableValues = tableData?.data || [];

    const contents = tableValues.find(({ id: contentId }) => contentId === id) || {};
    contents.word = value?.label || null;
    contents.situation = value?.situation || contents.situation;

    setTableData({
      ...tableData,
    });
  }


  const handleAddVariable = (e) => {
    const {
      name
    } = e.target;
    const variable = `{${name}}`;

    if (seletedInputId) {
      const selectedInput = document.getElementById(seletedInputId);
      selectedInput.value += variable;

    } else {
      alert("no input selected");
    }
  }

  const handleAddNewContent = () => {
    const tableValues = tableData?.data || [];

    if (tableValues?.some(({ id }) => id === "new-content") === false) {
      tableValues.unshift({ id: "new-content", contents: [], situation });
    }
    setTableData({
      ...tableData,
    });
  }

  const handlePageChange = (page) => {
    setPageNo(page);
  }


  const handleSaveOrEditContent = async (data) => {
    try {
      await addorSaveNewContent("", data);

      const { data: contentData } = await getContents("sad", { situation: situation, page: pageNo });
      setTableData(contentData);

      alert("Updated Succesfully");
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  }

  const handleDeleteAction = async (data) => {
    try {
      await deleteContent("", data.id);

      const { data: contentData } = await getContents("sad", { situation: situation, page: pageNo });
      setTableData(contentData);

      alert("Deleted Succesfully");
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  }


  //-----------------------------------------------> use Effects
  useEffect(() => {
    const setContents = async () => {
      try {
        const { data } = await getContents("sad", { situation: situation, page: pageNo });
        setTableData(data);
      } catch (error) {
        console.log(error);
      }
    }

    setContents();
  }, [pageNo, situation]);

  useEffect(() => {
    const getAndSetActions = async () => {
      try {
        setLoading(true)
        const { data: allActions } = await getAllActions();
        setActions(allActions);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
    }

    getAndSetActions();
  }, []);

  return (
    <div className="container">
      <div className="d-flex gap-3 m-3">
        <select
          className="form-select"
          onChange={(e) => {
            const { value } = e.target;
            setSituation(value);
          }}>
          <option value={"All"} defaultChecked>All</option>
          {
            actions.map(({ action, id, desc }) => (
              <option value={action} key={`filter-${id}`}>
                <span>{action}</span>
                <span>&nbsp;  &nbsp; {" -- "} &nbsp; &nbsp;</span>
                <span>{desc}</span>
              </option>
            ))
          }
        </select>
        <button className="btn btn-primary" onClick={handleAddNewContent}>Add</button>
      </div>

      <div className="d-flex gap-3 m-3 p-2 align-items-center justify-content-between form-control" style={{ width: "auto" }}>
        <span className="flex-1">Add Variables</span>

        <div className="btn-group">
          <button className="btn btn-primary" name="productName" onClick={handleAddVariable}>Product Name</button>
          <button className="btn btn-primary" name="discount" onClick={handleAddVariable}>Discount</button>
          <button className="btn btn-primary" name="price" onClick={handleAddVariable}>Current Price</button>
          <button className="btn btn-primary" name="quantity" onClick={handleAddVariable}>Quantity</button>
          <button className="btn btn-primary" name="offeredPrice" onClick={handleAddVariable}>Discounted Price</button>
          <button className="btn btn-primary" name="userLastAsk" onClick={handleAddVariable}>userLast Ask</button>
        </div>
      </div>
      {
        loading ? (<h1>Loading...</h1>) : (
          <ContentTable
            data={tableData}
            actions={actions}
            currentPage={pageNo}
            handlePageChange={handlePageChange}
            handleWordChange={handleWordChange}
            handleAddContent={handleAddContent}
            handleInputFocus={handleInputFocus}
            handleDeleteAction={handleDeleteAction}
            handleDeleteContent={handleDeleteContent}
            handleSituationChange={handleSituationChange}
            handleSaveOrEditContent={handleSaveOrEditContent}
          />
        )
      }
    </div>
  );
}

export default Content;