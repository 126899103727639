import React, { useEffect, useState } from 'react'
import { getSubscribeUser } from '../../../services/admin.service';
import DataTable from 'react-data-table-component';
import { Button, IconButton } from "@mui/material";
import { useNavigate } from 'react-router-dom';



const CustomersTable = ({ data = {}, currentPage, totalPage, handlePageChange, }) => {
  const {
    data: contents = [],
  } = data;


  const columns = [
    {
      name: "Name",

      selector: (row, index) => row?.name
    },
    {
      name: "Shop",
      selector: (row, index) =>
        <a href={`https://${row.shopId}`} target="_blank" rel="noreferrer">
          {row.shopId}
        </a>
    },
    {
      name: "Plan",
      selector: (row, index) => row.shopSubscription?.[0]?.name ?? "NA"
    },
    {
      name: "Plan Status",
      selector: (row, index) => (
        <p>
          {row.shopSubscription?.[0]?.status ?? "NA"}
        </p>
      )
    },
  ];

  return (
    <div className="form-control bg-white w-auto m-3">
      <DataTable
        columns={columns}
        data={data}
        customStyles={{
          headCells: {
            style: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
        }}
        pagination
        paginationServer
        paginationTotalRows={totalPage}
        onChangePage={handlePageChange}
        paginationDefaultPage={currentPage}

      />
    </div>);
}


export default function SubscribeUser() {
  const [loading, setLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate()
  const [totalPage, setTotalPage] = useState(0)
  const [tableData, setTableData] = useState({});

  const handlePageChange = (page) => {
    setPageNo(page);
  }

  useEffect(() => {
    setLoading(true)
    const setSubscribeData = async () => {
      try {
        const data = await getSubscribeUser(pageNo, limit);
        console.log(data)
        setTableData(data.data.rows);
        setTotalPage(data.data.count);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
    setSubscribeData();
  }, [pageNo])

  return (
    <>
      <IconButton aria-label="back">
        <Button onClick={(e) => navigate('/')}>Back</Button>
      </IconButton>
      {

        loading ? (<h1>Loading...</h1>) : (
          <>
            {
              tableData.length ? (
                <div className="container mx-2">
                  <h5>Subscribe Users</h5>
                  <CustomersTable
                    data={tableData}
                    totalPage={totalPage}
                    handlePageChange={handlePageChange}
                    currentPage={pageNo}
                  />
                </div>
              ) : (
                <h1>NO subscribe user </h1>
              )
            }
          </>
        )
      }

    </>
  )
}
